<style lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .main {
    flex-grow: 1;
    height: 100%;
    margin-top: 12px;
    display: flex;
    overflow: hidden;
    .left {
      width: 500px;
      height: 100%;
      overflow: hidden;
      background: white;
      flex-shrink: 0;
    }
    .img {
      flex-grow: 1;
      margin-left: 12px;
      height: 100%;
      background: white;
      border-radius: 8px;
      overflow: hidden;
      background-position: center center;
      background-size: contain;
      background-image: url("https://img2.baidu.com/it/u=4256143581,3992296132&fm=253&fmt=auto&app=138&f=JPEG?w=885&h=500");
    }
  }
}
</style>
<template>
  <div class="box">
    <!-- 过滤区域 -->
    <box-item>
      <div class="filter-container">
        <div class="filter-input-container">
          <!-- 区域 -->
          <div class="input-item">
            <div class="input-item-title">配电系统</div>
            <el-select v-model="v1">
              <el-option :value="1" label="高压配电系统"></el-option>
            </el-select>
          </div>

          <!-- <el-input></el-input> -->
        </div>

        <el-button type="primary" @click="loadData">提交</el-button>
      </div>
    </box-item>

    <div class="main">
      <div class="left">
        <box-item style="height:100%">
          <el-table
            :data="tableData"
            stripe
            height="620"
            style="width: 100%;overflow: scroll;"
          >
            <el-table-column prop="id" label="回路"> </el-table-column>
            <el-table-column prop="name" label="数据点" :width="200">
            </el-table-column>
            <el-table-column prop="num" label="实时值" :width="100">
            </el-table-column>
            <el-table-column prop="unit" label="单位"> </el-table-column>
          </el-table>
        </box-item>
      </div>

      <div class="img">
        <div class="img-bg"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref } from "vue";

const v1 = ref(1);
const v2 = ref(null);
const loadData = () => {};
const tableData = reactive([
  {
    id: "AHb11",
    name: "AHb11进线柜A相电流",
    num: "0",
    unit: "A",
  },
  {
    id: "AHb11",
    name: "AHb11进线柜B相电流",
    num: "0",
    unit: "A",
  },
  {
    id: "AHb11",
    name: "AHb11进线柜C相电流",
    num: "0",
    unit: "A",
  },
  {
    id: "AHb11",
    name: "AHb11进线柜功率因数",
    num: "-3.277",
    unit: "-",
  },
  {
    id: "AHb11",
    name: "AHb11进线柜反向无功Wq",
    num: "1000",
    unit: "kVARh",
  },
  {
    id: "AHb11",
    name: "AHb11进线柜反向有功Wp",
    num: "2000",
    unit: "kWh",
  },
  {
    id: "AHb11",
    name: "AHb11进线柜无功功率",
    num: "0",
    unit: "KVAR",
  },
  {
    id: "AHb11",
    name: "AHb11进线柜无功功率",
    num: "0",
    unit: "KVAR",
  },
  {
    id: "AHb11",
    name: "AHb11进线柜无功功率",
    num: "0",
    unit: "KVAR",
  },
  {
    id: "AHb11",
    name: "AHb11进线柜无功功率",
    num: "0",
    unit: "KVAR",
  },
  {
    id: "AHb11",
    name: "AHb11进线柜无功功率",
    num: "0",
    unit: "KVAR",
  },
  {
    id: "AHb11",
    name: "AHb11进线柜无功功率",
    num: "0",
    unit: "KVAR",
  },
  {
    id: "AHb11",
    name: "AHb11进线柜无功功率",
    num: "0",
    unit: "KVAR",
  },
  {
    id: "AHb11",
    name: "AHb11进线柜无功功率",
    num: "0",
    unit: "KVAR",
  },
  {
    id: "AHb11",
    name: "AHb11进线柜无功功率",
    num: "0",
    unit: "KVAR",
  },
  {
    id: "AHb11",
    name: "AHb11进线柜无功功率",
    num: "0",
    unit: "KVAR",
  },
  {
    id: "AHb11",
    name: "AHb11进线柜无功功率",
    num: "0",
    unit: "KVAR",
  },
]);
loadData();
</script>
